import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from '../../components/shared-components/Loading';
import { APP_PREFIX_PATH } from '../../configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route exact path={`${APP_PREFIX_PATH}/event/create`} component={lazy(() => import(`./event/create.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/event/:id/edit`} component={lazy(() => import(`./event/edit.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/event/:id`} component={lazy(() => import(`./event/detail.js`))} />
        <Route path={`${APP_PREFIX_PATH}/event`} component={lazy(() => import(`./event`))} />
        <Route path={`${APP_PREFIX_PATH}/withdrawal`} component={lazy(() => import(`./withdrawal`))} />
        <Route path={`${APP_PREFIX_PATH}/ticket`} component={lazy(() => import(`./ticket`))} />
        <Route path={`${APP_PREFIX_PATH}/setting`} component={lazy(() => import(`./setting`))} />
        <Route exact path={`${APP_PREFIX_PATH}/voucher/:id`} component={lazy(() => import(`./voucher/usage.js`))} />
        <Route path={`${APP_PREFIX_PATH}/voucher`} component={lazy(() => import(`./voucher`))} />
        <Route exact path={`${APP_PREFIX_PATH}/input`} component={lazy(() => import(`./input`))} />
        <Route exact path={`${APP_PREFIX_PATH}/input/create`} component={lazy(() => import(`./input/create.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/input/edit/:id`} component={lazy(() => import(`./input/edit.js`))} />
        <Route path={`${APP_PREFIX_PATH}/report/sales`} component={lazy(() => import(`./report/sales`))} />
        <Route exact path={`${APP_PREFIX_PATH}/verification`} component={lazy(() => import(`./verification`))} />
        <Route exact path={`${APP_PREFIX_PATH}/presence`} component={lazy(() => import(`./presence`))} />
        <Route exact path={`${APP_PREFIX_PATH}/presence/create`} component={lazy(() => import(`./presence/create.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/presence/edit/:id`} component={lazy(() => import(`./presence/edit.js`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);