import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/app/setting/profile"
  }
]

const signOut = (e) => {
  localStorage.removeItem('token');
  localStorage.removeItem('profile');
  document.location.reload();
}

let CDN_TOKEN;
switch (process.env.NODE_ENV) {
  case 'production':
    CDN_TOKEN = "9ad598fc-eb24-43fe-aa3a-cef92fcfa227";
    break;
  default:
    CDN_TOKEN = "d0594aeb-628e-42d6-bbea-c305780d3fc2";
    break;
}

export const NavProfile = ({ profile }) => {
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h5 className="mb-0">{profile.name}</h5>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          {
            (profile.avatar && profile.avatar !== "") ? <Avatar src={profile.avatar.includes('firebase') ? profile.avatar : process.env.REACT_APP_CDN_URL + '/get/' + profile.avatar} /> : <Avatar icon={<UserOutlined />} />
          }
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}


export default connect(null, null)(NavProfile)
