import {
  HomeOutlined,
  ProfileOutlined,
  BarcodeOutlined,
  FileTextOutlined,
  SwapOutlined,
  SettingOutlined,
  TagsOutlined,
  FormOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

let dashBoardNavTree = [{
  key: 'dashboard',
  path: `${APP_PREFIX_PATH}/dashboard`,
  title: 'sidenav.home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
}, {
  key: 'event',
  path: `${APP_PREFIX_PATH}/event`,
  title: 'sidenav.event',
  icon: ProfileOutlined,
  breadcrumb: false,
  submenu: []
}, {
  key: 'ticket',
  path: `${APP_PREFIX_PATH}/ticket`,
  title: 'sidenav.ticket',
  icon: BarcodeOutlined,
  breadcrumb: false,
  submenu: []
},
// {
//   key: 'verification',
//   path: `${APP_PREFIX_PATH}/verification`,
//   title: 'sidenav.verification',
//   icon: CheckCircleOutlined,
//   breadcrumb: false,
//   submenu: []
// },
{
  key: 'withdrawal',
  path: `${APP_PREFIX_PATH}/withdrawal`,
  title: 'sidenav.withdrawal',
  icon: SwapOutlined,
  breadcrumb: false,
  submenu: []
}, {
  key: 'voucher',
  path: `${APP_PREFIX_PATH}/voucher`,
  title: 'sidenav.voucher',
  icon: TagsOutlined,
  breadcrumb: false,
  submenu: []
}, {
  key: 'input',
  path: `${APP_PREFIX_PATH}/input`,
  title: 'sidenav.input',
  icon: FormOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'report',
  path: `${APP_PREFIX_PATH}/report/sales`,
  title: 'sidenav.report-sales',
  icon: FileTextOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'setting',
  path: `${APP_PREFIX_PATH}/setting`,
  title: 'sidenav.setting',
  icon: SettingOutlined,
  breadcrumb: false,
  submenu: []
}, {
  key: 'presence',
  path: `${APP_PREFIX_PATH}/presence`,
  title: 'sidenav.presence',
  icon: FormOutlined,
  breadcrumb: false,
  submenu: []
}]

let navigationConfig = [
  ...dashBoardNavTree
]


export default navigationConfig;
