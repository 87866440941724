import React from "react";
import {
   useLocation
} from "react-router-dom";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { API_BASE_URL } from 'configs/AppConfig'
import axios from "axios";

function useQuery() {
   return new URLSearchParams(useLocation().search);
}

function Redirect() {
   let query = useQuery();
   const to = query.get('to')
   const token = query.get('token')

   // window.location.href = to;
   if (token !== null) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.get(API_BASE_URL + '/auth/session')
         .then(resp => {
            localStorage.setItem('token', token);
            localStorage.setItem('profile', JSON.stringify(resp.data));
            if (to !== null) {
               window.location.href = to;
            } else {
               window.location.href = '/';
            }
         }).catch(error => {
            console.log(error);
            window.location.href = '/';
         })
   } else {
      window.location.href = '/auth/login';
   }

   return (
      <div>
         <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100%' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
         </div>
      </div>
   )
}

export default Redirect
