const dev = {
	API_ENDPOINT_URL: 'http://localhost:5001'
};

const prod = {
	API_ENDPOINT_URL: 'https://api.tiketseminar.com'
};

const test = {
	API_ENDPOINT_URL: 'https://sandbox-api.tiketseminar.com'
};

const sandbox = {
	API_ENDPOINT_URL: 'https://sandbox-api.tiketseminar.com'
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		case 'sandbox':
			return sandbox
		default:
			break;
	}
}

export const env = getEnv()
